<template>
  <div>
    <navbar />
    <mission-image />
    <strombinsoscope class="mt100" />
    <foot class="mt100" />
  </div>
</template>

<script>
import foot from "@/components/FooterBis.vue";
import navbar from "@/components/NavBar.vue";
import MissionImage from "../../components/PageMission/MissionImage.vue";
import strombinsoscope from "../../components/PageMission/MissionStrombinoscope.vue";
export default {
  components: {
    foot,
    navbar,
    MissionImage,
    strombinsoscope,
  },
  metaInfo() {
    return {
      title: this.$t("pageMission.metaTitle"),
      link: [
        {
          rel: "canonical",
          href: `https://micetracking.com${this.$route.path}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: this.$t("pageMission.metaDescription"),
        },
        {
          name: "keywords",
          content: this.$t("pageMission.metaKeywords"),
        },
      ],
    };
  },
};
</script>
