<template>
  <v-row justify="center">
    <v-col cols="12" sm="10" md="10" xl="8">
      <h1 class="blue-title mt50">{{ $t("pageMission.teamTitle") }}</h1>
      <div class="photo-portrait mt50">
        <div
          class="image-container yves"
          @mousemove="(event) => handleMouseMove(event, 'cerveau')"
          @mouseleave="handleMouseLeave"
        >
          <img src="@/assets/images/Yves.webp" alt="" class="portrait" />
          <img src="@/assets/images/cerveau-creatif.webp" alt="" class="cerveau" />
          <h3>{{ $t("pageMission.teamMembers[0].name") }}</h3>
          <h4>{{ $t("pageMission.teamMembers[0].role") }}</h4>
          <h5>{{ $t("pageMission.teamMembers[0].subtitle") }}</h5>
          <p>{{ $t("pageMission.teamMembers[0].description") }}</p>
        </div>
        <div
          class="image-container pascal"
          @mousemove="(event) => handleMouseMove(event, 'trophé')"
          @mouseleave="handleMouseLeave"
        >
          <img src="@/assets/images/PAscal.webp" alt="" class="portrait" />
          <img src="@/assets/images/coupe.webp" alt="" class="trophé" />
          <h3>{{ $t("pageMission.teamMembers[1].name") }}</h3>
          <h4>{{ $t("pageMission.teamMembers[1].role") }}</h4>
          <h5>{{ $t("pageMission.teamMembers[1].subtitle") }}</h5>
          <p>{{ $t("pageMission.teamMembers[1].description") }}</p>
        </div>
        <div
          class="image-container milan"
          @mousemove="(event) => handleMouseMove(event, 'guitare')"
          @mouseleave="handleMouseLeave"
        >
          <img src="@/assets/images/Milan.webp" alt="" class="portrait" />
          <img src="@/assets/images/guitare-electrique.webp" alt="" class="guitare" />
          <h3>{{ $t("pageMission.teamMembers[2].name") }}</h3>
          <h4>{{ $t("pageMission.teamMembers[2].role") }}</h4>
          <p>{{ $t("pageMission.teamMembers[2].description") }}</p>
        </div>
      </div>
      <div class="photo-portrait mb50">
        <div
          class="image-container morgan"
          @mousemove="(event) => handleMouseMove(event, 'raquette')"
          @mouseleave="handleMouseLeave"
        >
          <img src="@/assets/images/morgan.webp" alt="" class="portrait" />
          <img src="@/assets/ico/badminton.webp" alt="" class="raquette" />
          <h3>{{ $t("pageMission.teamMembers[3].name") }}</h3>
          <h4>{{ $t("pageMission.teamMembers[3].role") }}</h4>
          <p>{{ $t("pageMission.teamMembers[3].description") }}</p>
        </div>
        <div
          class="image-container mathis"
          @mousemove="(event) => handleMouseMove(event, 'carte')"
          @mouseleave="handleMouseLeave"
        >
          <img src="@/assets/images/mathis2.webp" alt="" class="portrait" />
          <img src="@/assets/images/carte-du-monde.webp" alt="" class="carte" />
          <h3>{{ $t("pageMission.teamMembers[4].name") }}</h3>
          <h4>{{ $t("pageMission.teamMembers[4].role") }}</h4>
          <p>{{ $t("pageMission.teamMembers[4].description") }}</p>
        </div>
        <div
          class="image-container simon"
          @mousemove="(event) => handleMouseMove(event, 'dé')"
          @mouseleave="handleMouseLeave"
        >
          <img src="@/assets/images/Simon.webp" alt="" class="portrait" />
          <img src="@/assets/images/de.webp" alt="" class="dé" />
          <h3>{{ $t("pageMission.teamMembers[5].name") }}</h3>
          <h4>{{ $t("pageMission.teamMembers[5].role") }}</h4>
          <p>{{ $t("pageMission.teamMembers[5].description") }}</p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.photo-portrait {
  display: flex;
  justify-content: space-between;
}

.photo-portrait .image-container {
  position: relative;
  width: 30%;
}

.photo-portrait .image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 55, 109, 0.7);
  /* Couleur noire avec opacité */
  opacity: 0;
  transition: opacity 0.3s;
  transition-duration: 0.8s;
}

.photo-portrait .image-container:hover::before {
  opacity: 1;
}

.photo-portrait .image-container h3 {
  position: absolute;
  left: 21%;
  top: 20%;
  width: 100%;
  transform: translate(-10%, -50%);
  text-align: left;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.photo-portrait .image-container h4 {
  position: absolute;
  left: 20%;
  top: 30%;
  width: 90%;
  transform: translate(-10%, -50%);
  text-align: left;
  color: var(--vertfonce);
  opacity: 0;
  transition: opacity 0.3s;
}

.photo-portrait .image-container h5 {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 100%;
  transform: translate(-10%, -50%);
  text-align: left;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.photo-portrait .image-container p {
  position: absolute;
  left: 20%;
  top: 70%;
  transform: translate(-9%, -50%);
  text-align: left;
  color: white;
  /* Couleur du texte */
  opacity: 0;
  transition: opacity 0.3s;
}

.photo-portrait .image-container:hover h3,
.photo-portrait .image-container:hover h4,
.photo-portrait .image-container:hover p {
  opacity: 1;
}

.pascal .trophé,
.mathis .carte,
.morgan .raquette,
.yves .cerveau,
.simon .dé,
.milan .guitare {
  position: absolute;
  display: none;
  /* Cache l'image initialement */
  pointer-events: none;
  width: 50px;
  height: auto;
  z-index: 5;
}

.photo-portrait .image-container:hover .trophé,
.photo-portrait .image-container:hover .carte,
.photo-portrait .image-container:hover .raquette,
.photo-portrait .image-container:hover .cerveau,
.photo-portrait .image-container:hover .dé,
.photo-portrait .image-container:hover .guitare {
  display: block;
  /* Affiche l'image au survol */
}

.photo-portrait div img {
  width: 100%;
}

.portrait {
  border: solid 1px var(--bleu);
}

.image-container h4 {
  margin-top: 25px;
}

@media (max-width: 1264px) {
  /* Styles for screens with a width less than or equal to 1264 pixels */
  .mission-image {
    height: 100vh;
    background-position: 40% 10%;
  }

  .mission-content {
    width: 100%;
    padding: 5%;
    left: 0%;
  }
}

@media (max-width: 767px) {
  .mission-content p {
    width: 100%;
  }

  .mission-content h1 {
    width: 100%;
    margin-top: 20px;
  }

  .photo-portrait {
    display: block;
  }

  .photo-portrait .image-container {
    position: relative;
    margin-left: 5%;
    width: 90%;
  }

  .photo-portrait div {
    width: 100%;
    text-align: left;
  }
}
</style>

<script>
export default {
  name: "MissionStrombinoscope",
  methods: {
    handleMouseMove(event, type) {
      const icon = event.currentTarget.querySelector(`.${type}`);
      if (icon) {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        icon.style.left = `${x}px`;
        icon.style.top = `${y}px`;
      }
    },
    handleMouseLeave(event) {
      const icons = event.currentTarget.querySelectorAll('img[class^="icon-"]');
      icons.forEach((icon) => {
        icon.style.display = "none";
      });
    },
  },
};
</script>
